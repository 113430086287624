import DetailedTree from '../../../tree/DetailedTree';
import { useTranslation } from 'react-i18next';

export default function Static({ tree }: { tree: DetailedTree | null }) {
  const { t } = useTranslation();

  if (!tree) return null;

  return (
    <div className="twp text-greehill-00">
      <h3 className="uppercase text-xs font-bold mt-8 mb-2 mx-3 text-outer-space-300">
        {t('treeDetails.inventory.siteFactors')}
      </h3>
      <ul className="divide-y divide-outer-space-500 [&>li]:px-3 [&>li]:py-2 [&>li]:flex">
        <StaticItem label={t('details.properties.streetAddress')} value={tree.streetAddress} />
        <StaticItem label={t('details.properties.onStreetName')} value={tree.onStreetName} />
        <StaticItem label={t('details.properties.addressFromParcel')} value={tree.addressFromParcel} />
        <StaticItem label={t('details.properties.sideLocation')} value={tree.sideLocation} />
        <StaticItem label={t('details.properties.landUse')} value={tree.landUse?.join(', ')} />
        <StaticItem label={t('details.properties.overheadUtilities')} value={tree.overheadUtilities?.join(', ')} />
        <StaticItem label={t('details.properties.growSpaceSize')} value={tree.growSpaceSize} />
        <StaticItem label={t('details.properties.growSpace')} value={tree.growSpace} />
        <StaticItem label={t('details.properties.parkName')} value={tree.parkName} />
      </ul>
      <h3 className="uppercase text-xs font-bold mt-8 mb-2 mx-3 text-outer-space-300">
        {t('treeDetails.inventory.additionalInformation')}
      </h3>
      <ul className="divide-y divide-outer-space-500 [&>li]:px-3 [&>li]:py-2 [&>li]:flex">
        <StaticItem label={t('details.properties.customerTagId')} value={tree.customerTagId} />
        <StaticItem label={t('details.properties.customerSiteId')} value={tree.customerSiteId} />
        <StaticItem label={t('details.properties.customerTreeId')} value={tree.customerTreeId} />
        <StaticItem label={t('details.properties.cultivarOrVariety')} value={tree.cultivarOrVariety} />
        <StaticItem label={t('details.properties.plantingYear')} value={tree.plantingYear?.toString()} />
        <StaticItem label={t('details.properties.owner')} value={tree.owner} />
      </ul>
      <h3 className="uppercase text-xs font-bold mt-8 mb-2 mx-3 text-outer-space-300">
        {t('treeDetails.inventory.latestPruningAction')}
      </h3>
      <ul className="divide-y divide-outer-space-500 [&>li]:px-3 [&>li]:py-2 [&>li]:flex">
        <StaticItem label={t('details.properties.latestPruningAction')} />
      </ul>
    </div>
  );
}

function StaticItem({ label, value }: { label: string, value?: string }) {
  return (
    <li className="flex">
      <div className="w-2/5 text-outer-space-200">{label}</div>
      <div className="w-3/5 font-medium">{value ? value : <span className="text-outer-space-500">-</span>}</div>
    </li>
  );
}
