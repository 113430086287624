import PageLayout from '../../components/UI/Page/PageLayout';
import greeHillLogo from '../../components/UI/Icon/svg/greehill-logo.svg';
import { CheckCircle, Home, NavArrowLeft, NavArrowRight, TaskList, Trash, WarningTriangle, Xmark } from 'iconoir-react';
import { TFunction, useTranslation } from 'react-i18next';
import Header from './Header';
import { useMatch, useNavigate } from 'react-router-dom';
import useTree from '../../tree/useTree';
import DataCollection from './DataCollection/DataCollection';
import { DisplayableTreeProperty, TreeDto } from '../../tree/Tree';
import { TrackableTreeProperty } from '../../tree/TrackableTreeProperty';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import TreeDisplayTile from './TreeDisplayTile/TreeDisplayTile';
import DetailedTree, { ViStatus } from '../../tree/DetailedTree';
import Button, { ButtonVariant } from '../../components/UI/Button/Button';
import DependencyInjectionContext from '../../DependencyInjectionContext';
import Checkbox from '../../components/UI/Checkbox/Checkbox';
import CircularIconButton from '../../components/UI/Button/CircularIconButton';
import { useTask } from '../../task-manager/useTask';
import { ContextFields } from '../Explore/UrlContext';
import AbsoluteModal from '../../components/Modal/absolute-modal/AbsoluteModal';
import useConfirmationModal from '../../components/UI/ConfirmationModal/useConfirmationModal';
import CurrentState from './CurrentState/CurrentState';
import Static from './Static/Static';
import { dismissibleErrorToast, dismissibleSuccessToast } from '../../components/UI/Toast/DismissibleToast';
import { useCurrentAccount } from '../../account/useAccounts';
import { Flippers } from '../../switches/Flippers';
import TreeDeleteModal from './TreeDeleteModal';

export type ExtraObject = DisplayableTreeProperty.CanopyHeight
  | DisplayableTreeProperty.CanopyWidth
  | DisplayableTreeProperty.TrunkDiameter
  | DisplayableTreeProperty.TrunkHeight
  | DisplayableTreeProperty.Height
  | DisplayableTreeProperty.StructuralCriticalRootZone
  | DisplayableTreeProperty.CriticalRootZone
  | 'all-dimension'
  | 'safetyFactor'
  | 'leaningAngle'
  | 'all-safety';

export type CohortValues = {
  [key in TrackableTreeProperty]: {
    mu: number,
    sigma: number
  }
};

export type Cohort = {
  cohortValues: CohortValues,
  genus: string,
  id: string,
  maxTrunkDiameter: number,
  minTrunkDiameter: number,
  name: string,
  organizationId: string
};

type DetailsContextPredicate = (value: ExtraObject[]) => ExtraObject[];
type DetailsContextType = {
  rulers: ExtraObject[],
  setRulers: (extra: ExtraObject[] | DetailsContextPredicate) => void,
  riskOverlayIsVisible: boolean,
  setRiskOverlayIsVisible: (value: boolean) => void,
  showRootZone: boolean,
  showCRZ: boolean,
  setCRZVisibility: (value: boolean) => void,
  showSCRZ: boolean,
  setSCRZVisibility: (value: boolean) => void,
  setRootZoneVisibility: (value: boolean) => void
};
export const DetailsContext = createContext<DetailsContextType>({
  rulers: [],
  setRulers: () => {},
  riskOverlayIsVisible: false,
  setRiskOverlayIsVisible: () => {},
  showRootZone: false,
  showCRZ: false,
  showSCRZ: false,
  setRootZoneVisibility: () => {},
  setCRZVisibility: () => {},
  setSCRZVisibility: () => {}
});

enum TABS {
  currentState = 'currentState',
  static = 'static',
  dataCollection = 'dataCollection'
}

const initializeTreeWithDefaultObservations = (tree: DetailedTree | null, t: TFunction ): DetailedTree | null => {
  if (!tree) return null;
  if (!tree.observations.map(it=> it.name).includes(DisplayableTreeProperty.LeaningAngle)) {
    tree.observations.push({
      name: DisplayableTreeProperty.LeaningAngle,
      mitigations: [],
      photoUrls: [],
      affectedTreePart: 'trunk',
      conditionOfConcern: false,
      note: `${t('parameters.leaningAngle')}: ${tree.leaningAngle.toFixed(2)}`
    });
  }
  if (!tree.observations.map(it=> it.name).includes(DisplayableTreeProperty.LiveCrownRatio) && tree.liveCrownRatio) {
    tree.observations.push({
      name: DisplayableTreeProperty.LiveCrownRatio,
      mitigations: [],
      photoUrls: [],
      affectedTreePart: 'crown',
      conditionOfConcern: false,
      note: `${t('tree.liveCrownRatio')}: ${tree.liveCrownRatio?.toFixed(2)}`
    });
  }
  return tree;
};

export default function Details() {
  const { t } = useTranslation();
  const { urlContext, treeService } = useContext(DependencyInjectionContext);
  const inventoryUrl = useMatch('/organizations/:organizationId/inventory/trees/:treeId/*');
  const taskUrl = useMatch('/organizations/:organizationId/remote-inspections/:taskId/*');
  const taskId = taskUrl?.params?.taskId || '';
  const organizationId = (taskId ? taskUrl?.params?.organizationId : inventoryUrl?.params?.organizationId) || '';
  const treeId = (taskId ? urlContext.getTreeId() : inventoryUrl?.params?.treeId) || '';
  const { tree, isTreeLoading } = useTree(organizationId, treeId);
  const { task, refetch } = useTask(organizationId, taskId);
  const { confirm, modal } = useConfirmationModal();

  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(TABS.currentState);
  const [editingSection, setEditingSection] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [editableTree, setEditableTree] = useState<DetailedTree | null>(initializeTreeWithDefaultObservations(tree, t));
  const [alertOpen, setAlertOpen] = useState(false);
  const [isCaptureModeActive, setCaptureModeActive] = useState(false);
  const [showRootZone, setRootZoneVisibility] = useState(false);
  const [showCRZ, setCRZVisibility] = useState(false);
  const [showSCRZ, setSCRZVisibility] = useState(false);
  const [rulers, setRulers] = useState<ExtraObject[]>([]);
  const [riskOverlayIsVisible, setRiskOverlayIsVisible] = useState<boolean>(false);
  const [deletingTree, setDeletingTree] = useState(false);
  const account = useCurrentAccount();
  const { organization } = account;

  const currentTreeIndex = task?.treeIds.findIndex(it => it === treeId) || 0;
  const { isPrevTree, isNextTree } = useMemo(() => {
    if (!task) return { isPrevTree: false, isNextTree: false };
    return { isPrevTree: currentTreeIndex > 0, isNextTree: currentTreeIndex < task.treeIds.length - 1 };
  }, [currentTreeIndex, task]);
  const isTreeEdited = JSON.stringify(tree) !== JSON.stringify(editableTree);

  // Do not clear tree (and screen) on switch to another tree
  useEffect(() => {
    if (tree) {
      setEditableTree(tree);
    }
  }, [tree]);

  const treeIsUnchanged = JSON.stringify(tree) === JSON.stringify(editableTree);

  const handleSave = async () => {
    if (!editableTree || !tree || !task) return;
    const changedProperties = Object.keys(editableTree).filter(key => editableTree[key] !== tree[key]);

    setLoading(true);
    const payload = {} as Partial<TreeDto>;
    changedProperties.forEach(property => {
      if (property === 'potentialTargets') {
        payload[property] = editableTree[property]!.filter(it => it.targetDescription.length > 0);
      } else {
        payload[property] = editableTree[property];
      }
    });
    payload.viStatus = ViStatus.TO_BE_SCHEDULED;

    try {
      await treeService.update(organizationId, treeId, payload);
      dismissibleSuccessToast(t('treeDetails.treeUpdated'));
      setEditingSection(null);
    } catch (e: unknown) {
      dismissibleErrorToast(t('genericError'));
    } finally {
      setLoading(false);
      const newTask = await refetch();
      const { id, nextTodoTreeId } = newTask.data || {};
      if (id && nextTodoTreeId) {
        navigate(`/organizations/${organizationId}/remote-inspections/${id}?${ContextFields.TreeId}=${nextTodoTreeId}`);
      }
    }
  };

  const handleDiscard = async () => {
    const config = {
      message: t('treeDetails.areYouSureYouWantToDiscard'),
      confirmText: t('treeDetails.discard'),
      cancelText: t('treeDetails.cancel')
    };

    if (await confirm(config)) {
      setEditingSection(null);
      if (!editableTree || !tree) return;
      setEditableTree(tree);
      dismissibleSuccessToast(t('treeDetails.changesDiscarded'));
    }
  };

  const tabStyle = 'flex-grow p-2 text-base text-outer-space-200 border-b-2 border-outer-space-600 hover:bg-outer-space-600';
  const selectedTabStyle = 'flex-grow p-2 text-base text-greehill-00 border-b-2 border-greehill-400 bg-outer-space-600';

  // eslint-disable-next-line @typescript-eslint/comma-dangle
  const handleChange = <T,>(property: string, value: T) => {
    setEditableTree(prev => ({
      ...prev,
      [property]: value
    } as DetailedTree));
  };

  const inventorySearchParams = new URLSearchParams(localStorage.getItem('inventorySearchParams') ?? '');
  inventorySearchParams.set('s', urlContext.getSidebarOpen() ? 'true' : 'false');

  return (
    <DetailsContext.Provider value={{ rulers, setRulers, riskOverlayIsVisible, setRiskOverlayIsVisible, showRootZone, setRootZoneVisibility, showCRZ, setCRZVisibility, showSCRZ, setSCRZVisibility }}>
      <PageLayout>
        <PageLayout.LeftNavExtensions>
          <img src={greeHillLogo} alt="greeHill"/>
        </PageLayout.LeftNavExtensions>

        <PageLayout.CenterNavExtensions>
        </PageLayout.CenterNavExtensions>

        <PageLayout.Content>
          <div className="pt-6 px-6 flex flex-col gap-3 rounded-tl-lg bg-outer-space-800 w-full h-full">
            {organization.isEnabled(Flippers.release24q3) ? (
              <div className="h-5 flex gap-2 text-outer-space-300">
                <div className="text-[13px] font-normal font-['Inter'] leading-tight">
                  {taskId ?
                    t('mainMenu.taskManager')
                    :
                    <div
                      className="cursor-pointer text-outer-space-100"
                      onClick={() => navigate({
                        pathname: `/organizations/${organization.id}/inventory`,
                        search: inventorySearchParams.toString()
                      })}
                    >
                      {t('mainMenu.insights')}
                    </div>
                  }
                </div>
                {task && (
                  <>
                    <NavArrowRight className="w-4 h-4"/>
                    <div className="text-[13px] font-normal font-['Inter'] leading-tight">
                      {task ? task.code : editableTree?.managedArea.code}
                    </div>
                  </>
                )}
                <NavArrowRight className="w-4 h-4"/>
                <div className="text-outer-space-100 text-[13px] font-normal font-['Inter'] leading-tight">
                  {t('treeDetails.tree')} {editableTree?.externalId}
                </div>
              </div>
            ) : (
              <div className="h-5 flex gap-2 text-outer-space-300">
                <Home className="w-4 h-4"/>
                <NavArrowRight className="w-4 h-4"/>
                <div className="text-[13px] font-normal font-['Inter'] leading-tight">
                  {taskId ? t('mainMenu.taskManager') : t('mainMenu.insights')}
                </div>
                <NavArrowRight className="w-4 h-4"/>
                <div className="text-[13px] font-normal font-['Inter'] leading-tight">
                  {task ? task.code : editableTree?.managedArea.code}
                </div>
                <NavArrowRight className="w-4 h-4"/>
                <div className="text-outer-space-100 text-[13px] font-normal font-['Inter'] leading-tight">
                  {t('treeDetails.tree')} {editableTree?.externalId}
                </div>
              </div>
            )}
            <Header tree={editableTree} task={task} isTreeEdited={isTreeEdited}/>
            <div className="flex gap-6 w-full flex-1">
              <div className="rounded-xl flex-1 relative">
                <div className="absolute inset-0">
                  {tree && <TreeDisplayTile tree={tree} isCaptureModeActive={isCaptureModeActive} setCaptureModeActive={setCaptureModeActive} />}
                </div>
              </div>
              <div className="max-w-[600px] basis-[50%] relative overflow-hidden">
                <div className="absolute inset-0 overflow-y-auto">
                  <nav className="twp flex bg-outer-space-800 justify-between sticky top-0 mb-4 z-20">
                    {Object.values(TABS).map(tab => (
                      <button
                        key={tab}
                        className={`${selectedTab === tab ? selectedTabStyle : tabStyle} disabled:opacity-75`}
                        onClick={() => setSelectedTab(tab)}
                      >
                        {t('treeDetails.tabs.' + tab)}
                      </button>
                    ))}
                  </nav>
                  {selectedTab === TABS.static &&
                    <>
                      <Static tree={editableTree} />
                      {organization.isEnabled(Flippers.release24q3) && !isLoading && !task && account.isAdminOrAbove() &&
                        <div className='flex justify-center'>
                          <Button
                            variant={ButtonVariant.SecondaryDanger}
                            onClick={() => setDeletingTree(true)}
                            className='max-w-fit px-5 py-2 flex justify-center gap-2 items-center'
                          >
                            <Trash fontSize={13}/>{t('treeDetails.deleteTree.button')}
                          </Button>
                        </div>}
                    </>}
                  {selectedTab === TABS.dataCollection &&
                    <DataCollection
                      key={editableTree?.id}
                      tree={editableTree}
                      handleChange={handleChange}
                      editingSection={editingSection}
                      setEditingSection={setEditingSection}
                      isCaptureModeActive={isCaptureModeActive}
                      setCaptureModeActive={setCaptureModeActive}
                    />}
                  {selectedTab === TABS.currentState &&
                    <CurrentState tree={editableTree} handleChange={handleChange} disableEdit={!taskId} />}
                </div>
              </div>
            </div>
            {task ? (
              <div className={`flex ${(task.treeIds || []).length > 1 ? 'justify-between' : 'justify-end'} mb-3 items-center text-greehill-00`}>
                {(task.treeIds || []).length > 1 && (
                  <div className="bg-outer-space-700 flex items-center gap-4 p-1 rounded-full">
                    <CircularIconButton
                      variant={ButtonVariant.Secondary}
                      icon={<NavArrowLeft className="w-5 h-5"/>}
                      disabled={!isPrevTree}
                      onClick={() => {
                        if (isTreeEdited) return setAlertOpen(true);
                        navigate(`/organizations/${organizationId}/remote-inspections/${task.id}?${ContextFields.TreeId}=${task.treeIds[currentTreeIndex - 1]}`);
                      }}
                    />
                    <div className="flex items-center gap-1">
                      <TaskList className="w-5 h-5"/>
                      <span>
                        <span className="text-xl">{currentTreeIndex + 1}</span> / <span className="text-base">{task.treeIds.length ?? ''}</span>
                      </span>
                    </div>
                    <CircularIconButton
                      variant={ButtonVariant.Secondary}
                      icon={<NavArrowRight className="w-5 h-5"/>}
                      disabled={!isNextTree}
                      onClick={() => {
                        if (isTreeEdited) return setAlertOpen(true);
                        navigate(`/organizations/${organizationId}/remote-inspections/${task.id}?${ContextFields.TreeId}=${task.treeIds[currentTreeIndex + 1]}`);
                      }}
                    />
                  </div>
                )}
                <div className="flex gap-5 items-center">
                  <div className="text-sm w-44">
                    <Button
                      variant={ButtonVariant.Secondary}
                      onClick={handleDiscard}
                      disabled={isTreeLoading || isLoading || treeIsUnchanged}
                      className="flex items-center justify-center gap-2 py-2"
                    >
                      <Xmark className="size-5"/>
                      Discard
                    </Button>
                  </div>
                  <SignButton
                    onClick={handleSave}
                    disabled={isTreeLoading || isLoading || (tree?.viStatus === ViStatus.TO_BE_SCHEDULED && !isTreeEdited)}
                  />
                </div>
              </div>
            ) : <div className="mb-4"></div>}
          </div>
          <AbsoluteModal isVisible={deletingTree} onHide={() => setDeletingTree(false)}>
            <TreeDeleteModal setDeletingTree={setDeletingTree} treeId={treeId}/>
          </AbsoluteModal>
          <AbsoluteModal isVisible={alertOpen} onHide={() => setAlertOpen(false)}>
            <section
              className="bg-outer-space-700 rounded-md p-10 max-w-sm text-greehill-00 flex flex-col gap-5 justify-center items-center">
              <div className="rounded-full bg-danger-600 w-fit p-3 flex justify-center items-center">
                <WarningTriangle />
              </div>
              <span className="text-center">
                {t('remoteInspection.pleaseSignOrDiscard')}
              </span>
              <div className="flex gap-5 w-full mt-3">
                <Button
                  variant={ButtonVariant.Secondary}
                  onClick={() => setAlertOpen(false)}
                >
                  {t('ok')}
                </Button>
              </div>
            </section>
          </AbsoluteModal>
          {modal}
        </PageLayout.Content>
      </PageLayout>
    </DetailsContext.Provider>
  );
}

function SignButton(props: { onClick: () => void, disabled: boolean }) {
  const [isOpen, setOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const handleClick = () => {
    if (!isOpen) return setOpen(true);
    if (isOpen && confirmed) {
      props.onClick();
      setOpen(false);
      setConfirmed(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    event.stopPropagation();
    const container = document.getElementById('sign-tree-button');
    if (!container?.contains(event.target as Node) && isOpen) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen]);

  return (
    <div className="twp relative text-greehill-00 text-sm" id="sign-tree-button">
      <Button
        onClick={handleClick}
        disabled={(isOpen && !confirmed) || props.disabled}
        className="relative w-44 text-nowrap flex items-center justify-center gap-2 rounded-full border border-greehill-600 py-2 z-20"
      >
        <CheckCircle className="size-4"/>
        Sign Inspection
      </Button>
      {isOpen && (
        <div className="absolute bottom-1/2 flex gap-2 p-3 pb-8 rounded-2xl max-w-44 bg-greehill-00 z-10 rounded-b text-black-haze-950">
          <div className="mt-1"><Checkbox checked={confirmed} onChange={() => setConfirmed(prev => !prev)} /></div>
          <div>Please check to confirm before signing</div>
        </div>
      )}
    </div>
  );
}
