import Button, { ButtonVariant } from '../../../components/UI/Button/Button';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { useCurrentAccount } from '../../../account/useAccounts';
import { CheckSquare, Download, Filter, PlusCircle, StatsReport, TableRows } from 'iconoir-react';
import FloatingBar from '../../../components/UI/FloatingBar/FloatingBar';
import { useTrees } from './useTrees';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { DisplayableTreeProperty, Tree } from '../../../tree/Tree';
import { TaskManagerView } from '../TaskManagerView';
import { useMatch, useNavigate } from 'react-router-dom';
import TreeTable from './TreeTable';
import { SortingDirection } from '../../../components/UI/SortButton/SortButton';
import { useTaskTemplatesWithTrees } from './useTaskTemplatesWithTrees';
import jobColumnColorMap from '../jobColumnColorMap';
import { JobColumnName } from './JobColumnName';
import FilterSelector from '../../Explore/components/FilterSelector';
import FilterCompound from '../../Explore/workspace/filter-compound/FilterCompound';
import FileDownloadButton from '../../Explore/workspace/components/FileDownloadButton';
import { getProperties } from './FilterConfig';
import ReportDialog from '../../Explore/reports/ReportDialog';
import useManagedAreas from '../../../managed-area/useManagedAreaList';
import { dismissibleErrorToast, dismissibleSuccessToast } from '../../../components/UI/Toast/DismissibleToast';

export default function TaskCreationFromTemplate() {
  const { t } = useTranslation();
  const { urlContext, taskManagerService } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();
  const navigate = useNavigate();
  const { managedAreaList } = useManagedAreas(organization.id);

  const selectedManagedAreaIds = urlContext.getManagedAreaIds();
  const selectedManagedAreas = managedAreaList.filter(it => selectedManagedAreaIds.includes(it.id));
  const reverseMASelection = urlContext.getReverseMASelection();
  const { categories, isError, isLoading: taskTemplateIsLoading } = useTaskTemplatesWithTrees(organization.id);

  const match = useMatch('/organizations/:organizationId/task-manager/create/:taskTemplateId');
  const taskTemplateId = match?.params.taskTemplateId;
  const taskTemplate = categories.flatMap(it => it.taskTemplates).find(it => it.id === taskTemplateId);

  const [sortProperty, setSortProperty] = useState<string | null>(null);
  const [sortingDirection, setSortingDirection] = useState<SortingDirection | null>(null);
  const [creating, setCreating] = useState(false);

  const [selectedTreeIds, setSelectedTreeIds] = useState<string[]>([]);
  const [selectedFields, setSelectedFields] = useState<DisplayableTreeProperty[]>([
    DisplayableTreeProperty.Height, DisplayableTreeProperty.TrunkHeight, DisplayableTreeProperty.TrunkDiameter, DisplayableTreeProperty.CanopyCircumference, DisplayableTreeProperty.ExternalId
  ]);
  const { trees, isLoading } = useTrees(organization.id, taskTemplate?.jobColumnName || JobColumnName.Inspection, selectedManagedAreaIds, reverseMASelection, [...selectedFields, ...getProperties(urlContext.getFilterConfiguration())], sortProperty, sortingDirection);

  const filteredTrees = useMemo(() => {
    if (isLoading || taskTemplateIsLoading) return [];
    const filter = FilterCompound.fromConfig(urlContext.getFilterConfiguration());
    return trees.filter(tree => taskTemplate?.trees.includes(tree.id) && filter.apply(tree as unknown as Partial<Tree>));
  }, [isLoading, taskTemplateIsLoading, JSON.stringify(urlContext.getFilterConfiguration()), trees]);

  const handleTreeSelection = (event: ChangeEvent<HTMLInputElement>, treeId: string) => {
    if (event.target.checked) {
      setSelectedTreeIds([...selectedTreeIds, treeId]);
    } else {
      setSelectedTreeIds(selectedTreeIds.filter(id => id !== treeId));
    }
  };

  const handleSort = (property: string) => {
    if (sortProperty !== property) {
      setSortProperty(property);
      setSortingDirection(SortingDirection.ASCENDING);
    } else {
      if (sortingDirection === SortingDirection.ASCENDING) {
        setSortingDirection(SortingDirection.DESCENDING);
      } else {
        setSortProperty(null);
        setSortingDirection(null);
      }
    }
  };
  const createTask = async () => {
    setCreating(true);
    const responseStatus = await taskManagerService.createTask({
      organizationId: organization.id,
      managedAreaIds: selectedManagedAreaIds,
      reverseMASelection,
      type: taskTemplate!.name,
      treeIds: selectedTreeIds,
      connectedModule: taskTemplate!.connectedModule,
      jobColumnName: taskTemplate!.jobColumnName
    });

    if (responseStatus === 201) {
      dismissibleSuccessToast(t('taskManager.taskCreatedSuccessfully'));
      navigate(`/organizations/${organization.id}/task-manager/${TaskManagerView.MANAGE}`);
    } else {
      dismissibleErrorToast(t('taskManager.taskCreationFailed'));
    }
    setCreating(false);
  };

  useEffect(() => {
    if (isError) dismissibleErrorToast(t('genericError'));
  }, [isError]);

  if (!taskTemplate) return null;

  return (
    <section className="twp w-full">
      <div className="flex gap-4 my-6">
        <h2 className="text-2xl font-semibold">{taskTemplate.name}</h2>
        <span className={`bg-${jobColumnColorMap[JobColumnName.Inspection].primary} px-2 flex items-center text-greehill-00 rounded-md`}>{t(`taskManager.jobColumnTitles.${taskTemplate.jobColumnName}`)}</span>
      </div>
      <div className="flex justify-between">
        <div className="flex items-center gap-4">
          <div>
            <FilterSelector key={JSON.stringify(urlContext.getFilterConfiguration())} disabled={false} />
          </div>
        </div>
        <div className="flex text-greehill-00 gap-2 bg-outer-space-700 rounded-lg px-3">
          <div className="flex items-center gap-1 pr-2 border-r border-outer-space-800"><CheckSquare />{selectedTreeIds.length}</div>
          <div className="flex items-center gap-1"><Filter />{filteredTrees.length}</div>
          <div className="flex items-center gap-1 pl-2 border-l border-outer-space-800"><TableRows />{trees.length}</div>
        </div>
      </div>
      {isLoading
        ? <Spinner />
        : <TreeTable
          trees={trees}
          selectedTreeIds={selectedTreeIds}
          setSelectedTreeIds={setSelectedTreeIds}
          selectedFields={selectedFields}
          setSelectedFields={setSelectedFields}
          handleTreeSelection={handleTreeSelection}
          sortProperty={sortProperty}
          sortingDirection={sortingDirection}
          handleSort={handleSort}
          taskOrTaskTemplateId={taskTemplateId!}
        />}
      <FloatingBar>
        <div className="flex items-center gap-4 text-sm">
          <div className="text-nowrap flex gap-1.5 items-center">
            <span className="font-bold text-xl">{selectedTreeIds.length}</span>{t('treeList.selected')}
          </div>
          <Button
            onClick={createTask}
            disabled={selectedTreeIds.length === 0 || creating}
          >
            <div className="flex text-nowrap items-center gap-2">
              <PlusCircle height={20} width={20} />
              {t('treeList.createTask')}
            </div>
          </Button>
          <FileDownloadButton
            header={selectedFields}
            treesProvider={() => Promise.resolve(trees)}
            windSpeed={organization.defaultWindSpeed}
          >
            <Button variant={ButtonVariant.Secondary} className="flex text-nowrap items-center justify-center py-[5px]">
              <Download className="size-5 pr-1" />
              {t('treeList.downloadData')}
            </Button>
          </FileDownloadButton>
          <Button variant={ButtonVariant.Secondary} className="flex text-nowrap items-center justify-center py-[5px]" onClick={() => urlContext.setReportOpen(true)}>
            <StatsReport className="size-5 pr-1"/>
            {t('reporting.generateReport')}
          </Button>
        </div>
      </FloatingBar>
      {urlContext.getReportOpen() &&
        <ReportDialog
          onHide={() => urlContext.setReportOpen(false)}
          managedAreas={selectedManagedAreas}
          filters={[]}
          taskTemplateId={taskTemplateId}
        />}
    </section>
  );
}
