import { useTranslation } from 'react-i18next';
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import DependencyInjectionContext from '../../../DependencyInjectionContext';
import { AccordionGroupCtx, ObservationAccordion } from './AccordionGroup';
import { useCurrentAccount } from '../../../account/useAccounts';
import { useMatch } from 'react-router-dom';
import { TreeDisplays } from '../TreeDisplayTile/TreeDisplays';
import { ObservationGallery } from './components/ObservationGallery/ObservationGallery';
import { MediaImagePlus, NavArrowLeft, WarningTriangle } from 'iconoir-react';
import { MultiSelect } from '../../../components/Settings/Select/Select';
import Checkbox from '../../../components/UI/Checkbox/Checkbox';
import AbsoluteModal from '../../../components/Modal/absolute-modal/AbsoluteModal';
import Button, { ButtonVariant } from '../../../components/UI/Button/Button';
import {
  CrownObservationOptionEnum,
  RootObservationOptionEnum,
  TrunkObservationOptionEnum
} from './observationOptions';
import DetailedTree, { Observation } from '../../../tree/DetailedTree';
import { Mitigation } from '../../../tree/Tree';
import { JobColumnName } from '../../TaskManager/create/JobColumnName';
import { useTaskTemplates } from '../../TaskManager/create/useTaskTemplates';

export function ObservationItem(props: ObservationItemProps) {
  const { t } = useTranslation();
  const { urlContext, treeService } = useContext(DependencyInjectionContext);
  const { setOpenAccordion, setEditingSection, openAccordion } = useContext(AccordionGroupCtx);
  const { organization } = useCurrentAccount();
  const { taskTemplates } = useTaskTemplates(organization.id);
  const mitigationOptions = taskTemplates
    .filter(template => template.jobColumnName !== JobColumnName.Inspection)
    .map(template => ({ value: template.id, label: template.name }));

  const taskUrl = useMatch('/organizations/:organizationId/remote-inspections/:taskId/*');
  const taskId = taskUrl?.params?.taskId || '';

  const thumbnailsRef = useRef<HTMLDivElement>(null);
  const [photos, setPhotos] = useState<string[]>(props.observation?.photoUrls || []);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    let observer: MutationObserver | null = null;
    if (!thumbnailsRef.current || !props.observation) return;
    if (props.isCaptureModeActive) {
      urlContext.setDetailsTreeDisplay(TreeDisplays.STREET_VIEW);
      observer = new MutationObserver(e => {
        if (!thumbnailsRef.current || !props.observation) return;
        const imageUrl = (e[0].addedNodes[0] as HTMLImageElement).src;
        const recordButton = document.getElementById('recordPhotoButton')?.children[0] as HTMLButtonElement;
        if (recordButton) recordButton.disabled = true;
        treeService
          .uploadObservationPhoto(organization.id, props.tree?.id ?? '', props.observation.name, imageUrl)
          .then((savedUrl: string) => {
            if (!props.observation) return;
            props.update({ ...props.observation, photoUrls: [...(props.observation.photoUrls || []), savedUrl] });
            setPhotos(prev => [...prev.filter(it => it !== imageUrl), savedUrl]);
            if (recordButton) recordButton.disabled = false;
          });
        setPhotos(prev => Array.from(new Set([...prev, imageUrl])));
      });
      observer.observe(thumbnailsRef.current, { childList: true });
    }

    return () => {
      observer?.disconnect();
    };
  }, [props.isCaptureModeActive, props.observation]);

  const updateNote = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    if (!props.observation) return;
    props.update({ ...props.observation, note: event.target.value });
  };

  const deleteImage = (url: string) => {
    if (!props.observation) return;
    props.update({ ...props.observation, photoUrls: props.observation.photoUrls.filter(it => it !== url) });
    setPhotos(prev => prev.filter(it => it !== url));
  };

  const toggleConditionOfConcern = () => {
    if (!props.observation) return;
    props.update({ ...props.observation, conditionOfConcern: !props.observation.conditionOfConcern });
  };

  const updateRecommendations = (recommendations: string[]) => {
    if (!props.observation) return;
    props.update({ ...props.observation, mitigations: recommendations.map(it => ({ taskTemplateId: it } as Mitigation)) });
  };

  useEffect(() => {
    return () => {
      if (props.isCaptureModeActive && props.checked) {
        props.setCaptureModeActive(false);
      }
    };
  }, [props.isCaptureModeActive, props.checked, openAccordion]);

  const isEditable = !!taskId;

  return (
    <>
      {props.observation && props.checked && (
        <ObservationAccordion section={props.name}>
          <div className="p-3 flex flex-col gap-3">
            <div>
              <div className="text-base text-outer-space-200">{t('taskManager.note')}</div>
              <textarea
                className="w-full mt-1 p-4 text-base outline-0 bg-outer-space-950 rounded resize-y"
                defaultValue={props.observation.note}
                placeholder={t('treeDetails.dataCollection.notePlaceholder')}
                onBlur={updateNote}
                disabled={!isEditable || ['leaningAngle', 'liveCrownRatio'].includes(props.observation.name)}
              />
            </div>
            <div>
              <div ref={thumbnailsRef} hidden/>
              <div className="flex gap-2 overflow-x-auto">
                <ObservationGallery
                  images={photos.map(it => ({ url: it, saving: !props.observation?.photoUrls?.includes(it) }))}
                  deleteImage={deleteImage}
                  editing={isEditable}
                  defaultItem={!props.isCaptureModeActive &&
                    <div
                      className={`bg-outer-space-600 rounded ${isEditable ? 'hover:bg-outer-space-500 cursor-pointer' : 'opacity-50'}`}
                      onClick={() => {
                        if (!isEditable) return;
                        props.setCaptureModeActive(true);
                        if (!thumbnailsRef.current) return;
                        thumbnailsRef.current.id = 'photoReceiver';
                      }}
                    >
                      <MediaImagePlus className="m-[24px]"/>
                    </div>
                  }/>
              </div>
            </div>
            <div>
              <div className="text-base text-outer-space-200">{t('taskManager.recommendations')}</div>
              <MultiSelect
                value={props.observation?.mitigations?.map(it => it.taskTemplateId) ?? []}
                options={mitigationOptions}
                onChange={updateRecommendations}
                label={''}
                placeholder={' '}
                containerStyle={{
                  marginTop: '4px',
                  backgroundColor: '#22272A',
                  borderRadius: '4px',
                  border: 'none'
                }}
                stylesOverride={{
                  control: { borderRadius: '4px', border: 'none' },
                  menu: { background: '#475257', top: '80%', border: 'none', boxShadow: '0px 6px 16px 0px rgba(34, 39, 42, 0.8)' },
                  dropdownIndicator: { color: '#CBD4D6', ':hover': { color: '#CBD4D6' } }
                }}
                isSearchable={true}
                disabled={!isEditable}
              />
            </div>
            <div className="flex gap-2 items-center mb-2">
              <div className="text-base text-outer-space-200">{t('treeDetails.dataCollection.conditionOfConcern')}</div>
              <Checkbox
                checked={props.observation.conditionOfConcern}
                onChange={toggleConditionOfConcern}
                disabled={!isEditable}
              />
            </div>
            <div className="flex justify-center">
              {isEditable && !['leaningAngle', 'liveCrownRatio'].includes(props.observation.name) && (
                <button
                  className="px-4 py-1 rounded-full border border-outer-space-400 hover:bg-greehill-00/10"
                  onClick={() => setConfirmDeleteOpen(true)}
                >
                  {t('treeDetails.dataCollection.remove')}
                </button>
              )}
            </div>
          </div>
          <AbsoluteModal isVisible={confirmDeleteOpen} onHide={() => setConfirmDeleteOpen(false)}>
            <section
              className="bg-outer-space-700 rounded-md p-10 max-w-sm text-greehill-00 flex flex-col gap-5 justify-center items-center">
              <div className="rounded-full bg-danger-600 w-fit p-3 flex justify-center items-center">
                <WarningTriangle/>
              </div>
              <span className="text-center">
                {t('treeDetails.dataCollection.confirmObservationDelete')}
              </span>
              <div className="flex gap-5 w-full mt-3">
                <Button
                  variant={ButtonVariant.Secondary}
                  onClick={() => setConfirmDeleteOpen(false)}
                >
                  {t('cancel')}
                </Button>
                <Button
                  variant={ButtonVariant.Secondary}
                  className="bg-danger-500 border-danger-500 hover:bg-danger-400"
                  onClick={() => {
                    setPhotos([]);
                    props.handleCheck();
                    setEditingSection(null);
                    setOpenAccordion(null);
                    setConfirmDeleteOpen(false);
                  }}
                >
                  {t('treeDetails.dataCollection.remove')}
                </Button>
              </div>
            </section>
          </AbsoluteModal>
        </ObservationAccordion>
      )}
      {(!props.observation || !props.checked) && (
        <div className="p-2 text-outer-space-200 text-base border-b border-outer-space-500 flex justify-between items-center">
          <Checkbox
            disabled={props.disabled || props.checked}
            checked={props.checked}
            onChange={() => {
              props.handleCheck();
              setEditingSection(props.name);
              setOpenAccordion(props.name);
            }}>
            {t(`virtualInspection.observationOptions.${props.name}`)}
          </Checkbox>
          <NavArrowLeft className="text-outer-space-500 h-5" />
        </div>
      )}
    </>
  );
}

interface ObservationItemProps {
  name: CrownObservationOptionEnum | TrunkObservationOptionEnum | RootObservationOptionEnum,
  checked: boolean,
  disabled: boolean,
  handleCheck: () => void,
  update: (observation: Observation) => void,
  observation?: Observation,
  tree: DetailedTree | null,
  isCaptureModeActive: boolean,
  setCaptureModeActive: Dispatch<SetStateAction<boolean>>
}
