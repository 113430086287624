import { InfoCircle, WarningTriangle } from 'iconoir-react';
import Button, { ButtonVariant } from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dismissibleSuccessToast } from '../../components/UI/Toast/DismissibleToast';
import { useCurrentAccount } from '../../account/useAccounts';
import { useNavigate } from 'react-router-dom';
import Checkbox from '../../components/UI/Checkbox/Checkbox';
import DependencyInjectionContext from '../../DependencyInjectionContext';

export default function TreeDeleteModal({ setDeletingTree, treeId }) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [deletingTreeError, setDeletingTreeError] = useState<boolean>(false);
  const [checkbox1, setCheckbox1] = useState<boolean>(false);
  const [checkbox2, setCheckbox2] = useState<boolean>(false);
  const [checkbox3, setCheckbox3] = useState<boolean>(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { organization } = useCurrentAccount();
  const { treeService } = useContext(DependencyInjectionContext);

  const handleDeleteTree = async () => {
    setLoading(true);
    const result = await treeService.deleteTree(organization.id, treeId);
    if (result) {
      dismissibleSuccessToast(t('treeDetails.deleteTree.treeDeleted'));
      setDeletingTree(false);
      navigate(`/organizations/${organization.id}/inventory`);
    } else {
      setDeletingTreeError(true);
    }
    setLoading(false);
  };

  if (deletingTreeError) return (
    <section
      className="bg-outer-space-700 rounded-md py-10 px-16 text-greehill-00 flex flex-col gap-5 justify-center items-center">
      <div className="rounded-full bg-warn-chardonnay-600 w-fit p-3 flex justify-center items-center">
        <InfoCircle />
      </div>
      <div className="text-2xl font-semibold">{t('treeDetails.deleteTree.errorTitle')}</div>
      <div>
        <span className="block text-center">
          {t('treeDetails.deleteTree.errorDescription1')}
        </span>
        <span className="block text-center">
          {t('treeDetails.deleteTree.errorDescription2')}
        </span>
      </div>
      <div className="flex gap-5 w-28 mt-3">
        <Button
          variant={ButtonVariant.Primary}
          onClick={() => {
            setDeletingTreeError(false);
            setDeletingTree(false);
          }}
        >
          {t('treeDetails.deleteTree.ok')}
        </Button>
      </div>
    </section>
  );

  return (
    <section
      className="bg-outer-space-700 rounded-md py-10 px-16 text-greehill-00 flex flex-col gap-5 justify-center items-center">
      <div className="rounded-full bg-danger-600 w-fit p-3 flex justify-center items-center">
        <WarningTriangle />
      </div>
      <div className="text-xl font-semibold">{t('treeDetails.deleteTree.title')}</div>
      <div>
        <span className='block text-center'>
          {t('treeDetails.deleteTree.description1')}
        </span>
        <span className='block text-center'>
          {t('treeDetails.deleteTree.description2')}
        </span>
      </div>
      <div className='flex flex-col gap-3.5 mt-2'>
        <Checkbox checked={checkbox1} onChange={() => setCheckbox1(!checkbox1)} className={checkbox1 ? 'bg-greehill-400 text-outer-space-700 border-0' : ''}>
          {t('treeDetails.deleteTree.checkbox1')}
        </Checkbox>
        <Checkbox checked={checkbox2} onChange={() => setCheckbox2(!checkbox2)} className={checkbox2 ? 'bg-greehill-400 text-outer-space-700 border-0' : ''}>
          {t('treeDetails.deleteTree.checkbox2')}
        </Checkbox>
        <Checkbox checked={checkbox3} onChange={() => setCheckbox3(!checkbox3)} className={checkbox3 ? 'bg-greehill-400 text-outer-space-700 border-0' : ''}>
          {t('treeDetails.deleteTree.checkbox3')}
        </Checkbox>
      </div>
      <div className="flex gap-5 w-full mt-3">
        <Button
          variant={ButtonVariant.Secondary}
          onClick={() => setDeletingTree(false)}
          disabled={isLoading}
        >
          {t('treeDetails.deleteTree.cancel')}
        </Button>
        <Button
          variant={ButtonVariant.PrimaryDanger}
          onClick={handleDeleteTree}
          disabled={isLoading || !checkbox1 || !checkbox2 || !checkbox3}
          className="flex justify-center"
        >
          {isLoading ? <Spinner inLine={true} size={0.4} /> : t('treeDetails.deleteTree.delete')}
        </Button>
      </div>
    </section>
  );
}
