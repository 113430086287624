import { Tree } from '../../tree/Tree';
import TreeService, { TreeListHeader } from '../../tree/TreeService';
import PartialTree from '../../tree/PartialTree';
import { FilteredPartialTree } from '../../tree/FilteredPartialTree';
import { Organization } from '../../organization/Organization';
import FilterConfig from '../TaskManager/create/FilterConfig';

export default class LazyTreeList {
  private readonly data: FilteredPartialTree[] = [];
  private total = 0;
  private totalFilteredByArea = 0;
  private nextPage: number | null = 0;
  private notFilteredCount = 0;
  private pinnedTreesCount = 0;
  private header: TreeListHeader[] = [];
  private managedAreas: { id: string, code: string }[] = [];
  private scientificNames: string[] = [];
  private speciesList: string[] = [];
  private genusList: string[] = [];
  private cancelled = false;

  constructor(
    private readonly treeService: TreeService,
    private readonly organization: Organization,
    private readonly organizationId: string,
    private readonly selectedManagedAreaIds: string[],
    private readonly reverseMASelection: boolean,
    private readonly fieldsToFetch: (keyof Tree)[],
    private readonly filterConfig: FilterConfig,
    private readonly sort: string | null,
    private readonly windSpeed: number,
    private isLoading = false
  ) {}

  async loadFirst(signal: AbortSignal) {
    const firstPage = await this.fetchData(signal);
    this.data.unshift(...firstPage.pinnedTrees);
    this.header = firstPage.header;
  }

  async load(signal: AbortSignal) {
    this.isLoading = true;
    while (this.nextPage !== null && !this.cancelled) {
      await this.fetchData(signal);
    }
    this.isLoading = false;
    this.cancelled = false;
  }

  cancel() {
    this.cancelled = true;
  }

  private async fetchData(signal: AbortSignal) {
    const page = await this.treeService.fetchWorkspaceData(
      this.organization,
      this.organizationId,
      this.selectedManagedAreaIds,
      this.reverseMASelection,
      this.fieldsToFetch,
      this.filterConfig,
      this.nextPage!,
      this.sort,
      signal,
      null,
      this.windSpeed
    );

    this.total = page.total + page.pinnedTreesCount;
    this.totalFilteredByArea = page.totalFilteredByArea;
    this.notFilteredCount = page.notFilteredCount;
    this.pinnedTreesCount = page.pinnedTreesCount;
    this.managedAreas = page.pagination.current === 0 ? page.managedAreas : this.managedAreas;
    this.scientificNames = page.scientificNames;
    this.speciesList = page.speciesList;
    this.genusList = page.genusList;
    this.data.push(...page.trees);
    this.nextPage = page.pagination.next;
    return page;
  }

  getRange(from: number, to: number): Promise<FilteredPartialTree[]> {
    return Promise.resolve(this.data.slice(from, to));
  }

  async getAll(): Promise<PartialTree[]> {
    await new Promise<void>(resolve => {
      const interval = setInterval(() => {
        if (!this.isLoading) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
    return Promise.resolve(this.data).then(trees => trees.filter(it => !it.filtered));
  }

  getNotPinned(): Promise<PartialTree[]> {
    return this.getRange(this.pinnedTreesCount, this.getTotal()).then(trees => trees.filter(it => !it.filtered));
  }

  getPinned(): Promise<PartialTree[]> {
    return this.getRange(0, this.pinnedTreesCount).then(trees => trees.filter(it => !it.filtered));
  }

  getFilteredCount() {
    return this.total - this.notFilteredCount;
  }

  getNotFilteredCount() {
    return this.notFilteredCount;
  }

  getTotal(): number {
    return this.total;
  }

  getTotalFilteredByArea(): number {
    return this.totalFilteredByArea;
  }

  getPinnedTreesCount(): number {
    return this.pinnedTreesCount;
  }

  findIndexById(id: string): Promise<number> {
    return Promise.resolve(this.data.findIndex(it => it.id === id));
  }

  getHeader(): TreeListHeader[] {
    return this.header;
  }

  getManagedAreas() {
    return this.managedAreas;
  }

  getSpecies() {
    // TODO: Figure out why removing this method causes type errors (LazyTreeList vs LegacyLazyTreeList)
  }

  getSpeciesList() {
    return this.speciesList;
  }

  getScientificNames() {
    return this.scientificNames;
  }

  getGenusList() {
    return this.genusList;
  }
}
