import styles from './TableDashboard.module.scss';
import { useTranslation } from 'react-i18next';
import { useTracking } from '../../../../analytics/useTracking';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import DependencyInjectionContext from '../../../../DependencyInjectionContext';
import { FunctionButton } from '../../../../components/UI/Button/LegacyButton';
import { Download, Filter, NavArrowDown, StatsReport, TableRows, ViewGrid } from 'iconoir-react';
import LazyTreeList from '../../LazyTreeList';
import FloatingBar from '../../../../components/UI/FloatingBar/FloatingBar';
import { ContextMenu } from '../../../../components/UI/ContextMenu/ContextMenu';
import { ContextMenuTrigger } from '../../../../components/UI/ContextMenu/ContextMenuTrigger';
import { ContextMenuContent } from '../../../../components/UI/ContextMenu/ContextMenuContent';
import { downloadBlob } from '../../table-view/components/FileDownloadButton';
import { useCurrentAccount } from '../../../../account/useAccounts';
import FileDownloadButton from './FileDownloadButton';
import ManagedAreaSelector from '../../components/ManagedAreaSelector';
import FilterSelector from '../../components/FilterSelector';
import { Property } from '../TreeTable';

export default function TableDashboard(props: TableDashboardProps) {
  const { t } = useTranslation();
  const { events, track } = useTracking();
  const { urlContext, treeService } = useContext(DependencyInjectionContext);
  const { organization } = useCurrentAccount();

  const [treeCountNotFiltered, setTreeCountNotFiltered] = useState(0);

  const generateReport = () => {
    urlContext.setReportOpen(true);
    track(events.REPORT_GENERATED_IN_TABLE);
  };

  useEffect(() => {
    const notFilteredCount = props.treeList?.getNotFilteredCount();
    setTreeCountNotFiltered(notFilteredCount || 0);
  }, [JSON.stringify(props.treeList?.getNotFilteredCount())]);

  const missingAreaSelection = useMemo(() => (!urlContext.getReverseMASelection() && urlContext.getManagedAreaIds().length === 0),
    [JSON.stringify(urlContext.getManagedAreaIds()), JSON.stringify(urlContext.getReverseMASelection())]);

  const toggleManagedAreaSelection = (id: string) => {
    const managedAreaIds = urlContext.getManagedAreaIds();
    if (urlContext.getManagedAreaIds().includes(id)) {
      urlContext.setManagedAreaIds(managedAreaIds.filter(it => it !== id));
    } else {
      urlContext.setManagedAreaIds([...managedAreaIds, id]);
    }
  };

  const toggleReverseMASelection = () => {
    urlContext.setReverseMASelection(urlContext.getReverseMASelection() ? urlContext.getManagedAreaIds().length !== 0 : true);
    urlContext.setManagedAreaIds([]);
  };

  const handleShpExport = async e => {
    e.stopPropagation();
    const response = await treeService.getShpExport(organization.id, urlContext.getReverseMASelection(), urlContext.getManagedAreaIds(), urlContext.getTreeFilterIds(), urlContext.getVisibleTableProperties() || []);
    const contentType = response.headers['content-type'];
    const fileName = response.headers['content-disposition'].match(/filename="([^"]*)"/)?.[1] || 'trees';

    downloadBlob(response.data, contentType, fileName);
  };

  return (
    <div className={styles.listDashboardContainer} data-testid="table-dashboard">
      {
        (urlContext.getTreeTableOpen() && treeCountNotFiltered) ? (
          <FloatingBar>
            <div className="ml-1 flex items-center gap-6 justify-between text-sm">
              <div className="text-nowrap flex gap-1.5 items-end leading-4">
                <span className="font-bold text-xl leading-none">{treeCountNotFiltered}</span>{t('treeList.selected')}
              </div>
              <div className="flex items-center gap-2">
                <ContextMenu>
                  <ContextMenuTrigger
                    className="flex text-nowrap items-center gap-2 flex-row px-3 py-1.5 border border-outer-space-500 rounded-[50px]">
                    <Download className="size-6" />
                    {t('treeList.downloadData')}
                  </ContextMenuTrigger>
                  <ContextMenuContent className="rounded-lg text-greehill-00 border border-outer-space-400 text-sm">
                    {props.treeList && (
                      <FileDownloadButton
                        header={props.properties}
                        treesProvider={() => props.treeList!.getAll()}
                        windSpeed={props.windSpeed}
                      >
                        <FunctionButton className="w-full py-1.5 items-start">
                          {t('treeList.viewDownloadDropdown.downloadAll')}
                        </FunctionButton>
                      </FileDownloadButton>
                    )}
                    <FunctionButton
                      onClick={handleShpExport}
                      className="w-full py-1.5 items-start"
                    >
                      {t('treeList.viewDownloadDropdown.downloadAllShp')}
                    </FunctionButton>
                  </ContextMenuContent>
                </ContextMenu>
                <FunctionButton variant="rounded" onClick={generateReport}>
                  <div className="flex items-center gap-2 flex-row">
                    <StatsReport className="size-5" />
                    {t('reporting.generateReport')}
                  </div>
                </FunctionButton>
              </div>
            </div>
          </FloatingBar>
        )
          :
          null
      }
      <div className={styles.functionButtonsContainer}>
        <div className={styles.areaSection}>
          <div className={styles.dropdownContainer}>
            <div>
              <div className="twp flex items-center gap-4">
                <FunctionButton
                  variant="rounded"
                  icon={<ViewGrid />}
                  disabled={true}
                >
                  <span className="flex items-center">{t('taskManager.mainView')} <NavArrowDown /></span>
                </FunctionButton>
                <ManagedAreaSelector
                  selectedManagedAreaIds={urlContext.getManagedAreaIds()}
                  setSelectedManagedAreaIds={(ids: string[]) => urlContext.setManagedAreaIds(ids)}
                  toggleSelection={toggleManagedAreaSelection}
                  reverseMASelection={urlContext.getReverseMASelection()}
                  toggleReverseMASelection={toggleReverseMASelection}
                />
                <div className="border-l border-outer-space-400">
                  <FilterSelector disabled={missingAreaSelection} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.functionButtonsContainer}>
        <div className="twp flex text-greehill-00 bg-outer-space-700 rounded-lg px-3">
          <div className="flex items-center gap-1 pr-2 border-outer-space-800 py-2">
            <Filter />{props.treeList?.getNotFilteredCount() || 0}
          </div>
          <div className="flex items-center gap-1 pl-2 border-l border-outer-space-800 py-2">
            <TableRows />{props.treeList?.getTotal() || 0}
          </div>
        </div>
      </div>
    </div>
  );
}

interface TableDashboardProps {
  treeList: LazyTreeList | null,
  windSpeed: number,
  properties: Property[]
}
