import { Children, ReactElement } from 'react';

import Navbar from '../../Navbar/Navbar';
import styles from './PageLayout.module.scss';
import Sidebar from './Sidebar';

export default function PageLayout(props: PageLayoutProps) {
  const leftNavExtensions = Children.map(props.children, child => (child.type === LeftNavExtensions ? child : null));
  const centerNavExtensions = Children.map(props.children, child => (child.type === CenterNavExtensions ? child : null));
  const rightNavExtensions = Children.map(props.children, child => (child.type === RightNavExtensions ? child : null));

  const content = Children.map(props.children, child => (child.type === Content ? child : null));

  return (
    <div className={styles.container} data-testid='page-layout'>
      {<Navbar details={!!props.detailsNavbar} borderless={!!props.borderless}>
        <Navbar.Left>
          {leftNavExtensions}
        </Navbar.Left>

        <Navbar.Center>
          {centerNavExtensions}
        </Navbar.Center>

        <Navbar.Right>
          {rightNavExtensions}
        </Navbar.Right>
      </Navbar>
      }
      <div className="bg-outer-space-950 w-full h-full flex gap-3">
        <Sidebar />
        <div className="relative w-full h-full flex-1 overflow-auto rounded-tl-lg drop-shadow-lg">{content}</div>
      </div>
    </div>
  );
}

function LeftNavExtensions({ children }) {
  return children;
}

function CenterNavExtensions({ children }) {
  return children;
}

function RightNavExtensions({ children }) {
  return children;
}

PageLayout.LeftNavExtensions = LeftNavExtensions;
PageLayout.CenterNavExtensions = CenterNavExtensions;
PageLayout.RightNavExtensions = RightNavExtensions;

function Content({ children }) {
  return children;
}

PageLayout.Content = Content;

interface PageLayoutProps {
  children: ReactElement | ReactElement[],
  detailsNavbar?: boolean,
  borderless?: boolean
}
